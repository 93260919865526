@font-face {
  font-family: "House Slant";
  src: url('./assets/fonts/HouseSlant/HouseSlant.eot');
  src: url("./assets/fonts/HouseSlant/HouseSlant.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/HouseSlant/HouseSlant.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Muli', sans-serif;
}


